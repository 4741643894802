import Vue from "vue";
import { Icon } from "vant";
import { Tab, Tabs } from "vant";
import { Notify } from "vant";
import { Button } from "vant";
import { Tabbar, TabbarItem } from "vant";
import { NavBar } from "vant";
import { Cell, CellGroup } from "vant";
import { Tag } from "vant";
import { Grid, GridItem } from "vant";
import { Toast } from "vant";
import { Skeleton } from "vant";
import { Empty } from "vant";
import { Search } from "vant";
import { ActionSheet } from "vant";
import { Form } from "vant";
import { Field } from "vant";
import { Area } from "vant";
import { Popup } from "vant";
import { Collapse, CollapseItem } from "vant";
import { List } from "vant";
import { Dialog } from "vant";
import { Divider } from "vant";
import { Picker } from "vant";
import { Step, Steps } from "vant";
import { Calendar } from "vant";
import { SubmitBar } from "vant";

// 全局注册
Vue.use(Icon);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Notify);
Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(NavBar);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Tag);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Toast);
Vue.use(Skeleton);
Vue.use(Empty);
Vue.use(Search);
Vue.use(ActionSheet);
Vue.use(Form);
Vue.use(Field);
Vue.use(Area);
Vue.use(Popup);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(List);
Vue.use(Dialog);
Vue.use(Divider);
Vue.use(Picker);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Calendar);
Vue.use(SubmitBar);
